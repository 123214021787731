<template>
  <AuthLayout>
    <div class="auth">
      <h1 class="auth__title">Восстановление пароля</h1>
      <div class="auth__body">
        <form @submit.prevent="submit">
          <PasswordAtomInput
            name="Новый пароль"
            v-model="form.password"
            :error="errors.password ?? errors.token"
          />
          <AtomInput
            type="password"
            name="Повторите пароль"
            v-model="form.password_confirmation"
            :error="errors.password_confirmation"
          />
          <BaseButton big color-primary :class="{ pending: loading }">Изменить пароль</BaseButton>
        </form>
      </div>
    </div>
  </AuthLayout>
</template>

<script setup>
import { passwordRestore } from '@/api/auth'
import useForm from '~/composables/useForm'
import AuthLayout from '@/components/layouts/AuthLayout'
import BaseButton from '@/components/common/BaseButton'
import PasswordAtomInput from '@/components/common/PasswordAtomInput'
import AtomInput from '@/components/common/AtomInput'

useHead({
  title: 'Восстановление пароля'
})

const route = useRoute()
const router = useRouter()
const setToken = inject('setToken')
const user = inject('user')

const myProfileRoute = useMyProfileRoute()

const {
  form,
  errors,
  submit,
  loading
} = useForm(passwordRestore, async data => {
  await setToken(data?.token)
  await router.push(
    route.query.redirect ?? myProfileRoute.value
  )
})

watch(() => route.query?.token, value => (form.value.token = value), { immediate: true })
</script>
